/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    shape, string, number,
} from 'prop-types';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const useStyles = makeStyles(() => ({
    copyContainer: {
        fontSize: 14,
        '& p': {
            margin: '0',
            textAlign: 'left',
            '& link': {
                color: '#2f2f2f',
            },
        },
        '& p:only-child': {
            textAlign: 'center',
        },
        '& a': {
            color: 'rgb(0, 0, 238)',
            textDecoration: 'underline',
            fontSize: 'inherit',
        },
    },
    container: (styleObj) => ({
        padding: '10px',
        width: styleObj?.width ? `${styleObj?.width}px` : '100%',
        textAlign: styleObj?.justify,
    }),
    footerV3Container: (styleObj) => ({
        padding: '0px 15px 0px 0px',
        width: '100%',
        textAlign: styleObj?.justify || 'center',
        '& a': {
            color: 'inherit',
            textDecoration: 'none',
            borderBottom: ' 1px solid',
        },
        '& p': {
            textAlign: styleObj?.justify || 'center',
        },

    }),
}));

const FooterCopy = ({ data }) => {
    const classes = useStyles(data);
    const footerV3Enabled = useSelector(getFeatureFlag('is-footer-v3-enabled'));
    // using for accessibility to add the aria-hidden true.
    const LinkOrReference = (prop) => {
        const { href, children } = prop;
        return <a href={href} aria-hidden="true" tabIndex="-1">{children}</a>;
    };

    return (
        <div className={`${classes.container} ${footerV3Enabled ? classes.footerV3Container : ''}`}>
            <ReactMarkdown
                renderers={{
                    linkReference: LinkOrReference,
                }}
                className={classes.copyContainer}
                escapeHtml={false}
                source={data?.copy}
            />
        </div>
    );
};

FooterCopy.propTypes = {
    data: shape({
        copy: string.isRequired,
        justify: string,
        width: number,
    }).isRequired,
};

export default FooterCopy;
