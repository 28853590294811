/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import mbpLogger from 'mbp-logger';
import { useSelector } from 'react-redux';
import { useUIDSeed } from 'react-uid';
import { useMediaQuery } from '@material-ui/core';
import { getPresentationFamily } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getBrandName } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { GRAPHQL_ENV } from '../../../gql';
import FooterRow from './Partials/FooterRow';
import DesktopMobile from '../../../helpers/DesktopMobile/DesktopMobile';
import DesktopGraphqlFooter from './DesktopGraphqlFooter';
import MobileGraphqlFooter from './MobileGraphqlFooter';
import { getActiveABTest } from '../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';

const useStyles = makeStyles((theme) => ({
    appFooter: {
        color: theme.palette.grey['50'],
        marginTop: theme?.palette?.footer?.marginTop,
        [theme.breakpoints.down(960)]: {
            marginTop: 0,
        },
    },
    addonPageDesktopFooter: {
        width: '100%',
    },
}));

const GraphqlFooterFood = () => {
    const classes = useStyles();
    const mobileFooterHeaderForTab = useSelector(getActiveABTest('mobileFooterHeaderForTab'));
    const isTabWidth = useMediaQuery('(max-width: 1025px)');
    const brandName = useSelector(getBrandName);
    const presentationFamily = useSelector(getPresentationFamily);

    const isHeaderTestEnableForTablet = mobileFooterHeaderForTab === 'variant';
    const FOOTER_QUERY = gql`
            query FooterQuery {
                findContent(brand: "${brandName}",
                environment: "${GRAPHQL_ENV}",
                locale: "en-us"
                contentType: "footer_v2"
                includeRefs:["footer_middle.menu_group_links.menu_group_link_ref","mobile_customer_service.phone.viewport","mobile_customer_service.chat.viewport","mobile_customer_service.chat.operating_system_segment_reference"]
                ) {
                    content
                }
            }
        `;

    const { data, loading, error } = useQuery(FOOTER_QUERY);

    if (loading) {
        return null; // skeleton component
    }

    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: FOOTER_QUERY,
            component: 'GraphqlFooter.js',
            message: 'Error loading data from Graphql',
            env: GRAPHQL_ENV,
            error,
        });
        return null;
    }

    if (!data) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: FOOTER_QUERY,
            component: 'GraphqlFooter.js',
            message: 'No data returned for query',
            env: GRAPHQL_ENV,
        });
        return null;
    }

    if (!data.findContent?.content) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: FOOTER_QUERY,
            component: 'GraphqlFooter.js',
            message: 'No content returned for query',
            env: GRAPHQL_ENV,
        });
        return null;
    }

    const footer = data.findContent.content.entries;
    const desktopFooter = footer.find((footerArr) => footerArr.viewport === 'Desktop');
    const mobileFooter = footer.find((footerArr) => footerArr.viewport === 'Mobile');

    let isCheckoutFlow = false;

    if (typeof window !== 'undefined') {
        const pathName = window.location.pathname;
        const checkoutPaths = ['/add-ons/', '/cart/', '/checkout/'];
        isCheckoutFlow = (checkoutPaths.some((path) => pathName.includes(path)));
    }

    const getFooterRows = (rows, isMobile) => {
        const rowData = [];
        const seed = useUIDSeed();
        // Align rows in array according to their position
        if (isMobile) {
            if (!isCheckoutFlow) {
                rowData.push({ row: rows?.mobile_customer_service || {}, key: 'mobileCustomerService' });
                rowData.push({ row: rows.footer_top || {}, key: 'footerTop' });
                rowData.push({ row: rows.footer_middle || {}, key: 'footerMiddle' });
            }
            rowData.push({ row: rows.footer_bottom || {}, key: 'footerBottom' });
        } else {
            if (!isCheckoutFlow) {
                rowData.push({ row: rows.footer_top || {}, key: 'footerTop' });
                rowData.push({ row: rows.footer_middle || {}, key: 'footerMiddle' });
            }
            rowData.push({ row: rows.footer_bottom || {}, key: 'footerBottom' });
        }

        return rowData.map(((item) => (
            isMobile
                ? (
                    <FooterRow
                        key={seed(item.row)}
                        presentationFamily={presentationFamily}
                        row={item.row}
                        isMobile={isMobile}
                        blockKey={item.key}
                        chatBlock={rows?.mobile_customer_service?.[0] || {}}
                    />
                )
                : (
                    <FooterRow
                        key={seed(item.row)}
                        presentationFamily={presentationFamily}
                        row={item.row}
                        isMobile={isMobile}
                        blockKey={item.key}
                    />
                )
        )));
    };

    if (isHeaderTestEnableForTablet && isTabWidth) {
        return (
            <>
                <MobileGraphqlFooter
                    presentationFamily={presentationFamily}
                    getFooterRows={getFooterRows}
                    data={mobileFooter}
                />
            </>
        );
    }
    return (
        <footer id="global_footer_container" data-testid="global_footer_container" className={isCheckoutFlow ? `${classes.addonPageDesktopFooter} ${classes.appFooter}` : `${classes.appFooter}`}>
            <DesktopMobile
                breakpoint={767}
                desktop={() => (
                    <DesktopGraphqlFooter
                        getFooterRows={getFooterRows}
                        data={desktopFooter}
                    />
                )}
                mobile={() => (
                    <MobileGraphqlFooter
                        presentationFamily={presentationFamily}
                        getFooterRows={getFooterRows}
                        data={mobileFooter}
                    />
                )}
            />
        </footer>
    );
};

export default (GraphqlFooterFood);
