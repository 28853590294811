/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ReactHtmlParser from 'react-html-parser';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { string, func, bool } from 'prop-types';

const useStyles = makeStyles((theme) => ({
    title: {
        padding: '10px 15px 10px',
        fontFamily: theme.typography.fontFamily,
        textAlign: 'center',
        textTransform: 'uppercase',
        postion: 'relative',
        '& h6': {
            fontSize: '1rem;',
            fontWeight: '600',
            borderBottom: '2px solid',
            padding: '5px 0px',
            borderBottomColor: theme.palette.modalBg,
        },
    },

    dialogMain: {
        '& div': {
            '& div': {
                borderRadius: 'initial',
                maxWidth: '960px',
            },
        },
    },
    closeIcon: {
        position: 'absolute',
        top: '13px',
        right: '13px',
        cursor: 'pointer',
    },
    dialogContent: {
        fontSize: '14px',
        color: theme.palette.grey1,
    },
}));

const PopupModal = ({
    title, data, show, closeDialog,
}) => {
    const classes = useStyles();
    return (
        <Dialog className={classes.dialogMain} open={show} aria-labelledby="form-dialog-title">
            <DialogTitle className={classes.title} id="form-dialog-title">
                <span>
                    {title}
                </span>
                <span role="presentation" onClick={() => closeDialog()} data-testid="dialog-close">
                    <CloseIcon className={classes.closeIcon} />
                </span>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <span className={classes.dialogContent}>
                        <span key={`${title}`}>
                            {/* replaces newlines with break */}
                            { ReactHtmlParser(data.replace(/(?:\r\n|\r|\n)/g, '<br />')) }
                        </span>
                    </span>
                </DialogContentText>
            </DialogContent>
        </Dialog>

    );
};

PopupModal.propTypes = {
    show: bool.isRequired,
    data: string.isRequired,
    title: string.isRequired,
    closeDialog: func.isRequired,
};
export default PopupModal;
