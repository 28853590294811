/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    func, shape, array, string,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import getStyleOverrides from './Partials/getStyleOverrides';
import LegalLinksContainer from '../GraphqlFooter/LegalLinksContainer';
import ShopTheSiteBanner from '../../../pages/Account/components/GiftList/ShopTheSiteBanner';
import { getBrand, getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import LegalRecaptchaLinks from '../GraphqlFooter/LegalRecaptchaLinks';

const useStyles = makeStyles((theme) => ({
    footerContainer: {
        // Default background color, will be overridden by contentstack value
        backgroundColor: theme.palette.desktopBgFooter || '#f2f2f2',
        paddingBottom: 20,
    },
    rowsContainer: {
        maxWidth: 1440,
        margin: '15px auto',
    },
    rowsV3Container: {
        display: 'grid',
        rowGap: '1rem',
    },

}));

/*
    The footer gets three rows from contentstack
    * footer_top -- Social Links, copy, Link (Button or Menu)
    * footer_middle -- Menu Link Group, Social Links
    * footer_bottom -- Social Link, Copy, Link, Copyright

    Each item in row has a column field
    if column field is empty by default it will be column 1 and centered

    Columns will placed accoriding to thier index.

    if more than 1 item has some column index then it will be placed in 2 rows
    according to thier placement in contentstack
*/

const DesktopGraphqlFooter = ({
    data, getFooterRows,
}) => {
    if (!data) return null;
    const footerV3Enabled = useSelector(getFeatureFlag('is-footer-v3-enabled'));
    const isRecaptchaCheckoutEnabled = useSelector(getFeatureFlag('is-giftcard-btn-captcha-enabled'))
        || useSelector(getFeatureFlag('is-placeorder-btn-captcha-enabled'));
    const brand = useSelector(getBrand);

    const classes = useStyles();

    // Return each row i.e footer_top, footer_middle, footer_bottom
    return (
        <div className={classes.footerContainer} style={getStyleOverrides(data)} data-testid="footer-desktop">
            <div className={`${classes.rowsContainer}  ${footerV3Enabled ? classes.rowsV3Container : ''}`}>
                {getFooterRows(data)}
            </div>
            <LegalLinksContainer />
            <LegalRecaptchaLinks isRecaptchaCheckoutEnabled={isRecaptchaCheckoutEnabled} legalData={data} brand={brand} />
            <ShopTheSiteBanner />
        </div>
    );
};

DesktopGraphqlFooter.propTypes = {
    data: shape({
        background_color: shape({
            color: string,
        }).isRequired,
        text_color: shape({
            color: string,
        }).isRequired,
        footer_top: array,
        footer_middle: array,
        footer_bottom: array,
    }),
    getFooterRows: func.isRequired,
};

DesktopGraphqlFooter.defaultProps = {
    data: {},
};

export default DesktopGraphqlFooter;
