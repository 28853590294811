/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export default function ifExternalUrl(event, url) {
    if (url && url.includes('https://' || url.includes('http://')) && typeof window !== 'undefined') {
        event.preventDefault();
        window.open(url);
    }
}
