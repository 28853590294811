/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    string, bool, array, object,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useUIDSeed } from 'react-uid';
import { useSelector } from 'react-redux';
import FooterLink from './FooterLink';
import DesktopFooterBlock from '../../GraphqlFooter/DesktopFooterBlock/DesktopFooterBlock';
import FooterSocialLinks from './FooterSocialLinks';
import PopupLinks from './PopupLinks';
import FooterCopy from './FooterCopy';
import MobileFooterBlock from '../../GraphqlFooter/MobileFooterBlock/MobileFooterBlock';
import EmailSubscribeBox from '../../../ContentTypeComponents/EmailSubscrube/EmailSubscribeBox';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import FooterTop from './FooterTop';
import CustomerServiceBlock from '../../GraphqlFooter/Partials/CustomerServiceBlock';

const useStyles = makeStyles((theme) => ({
    desktopRowContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    footerV3Container: {
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        marginLeft: '25px',
        [theme.breakpoints.down(1450)]: {
            margin: '0px 12px',
        },
    },
}));

/*
    FooterRow =>
    Takes a row from DesktopGraphqlFooter and renders the columns inside it according to it's index
*/

const FooterRow = ({
    row,
    isMobile,
    presentationFamily,
    blockKey,
    chatBlock,
}) => {
    if (!row) return null;
    const seed = useUIDSeed();
    const classes = useStyles();
    const columnsArray = [];
    const rowsArray = [];
    const footerV3Enabled = useSelector(getFeatureFlag('is-footer-v3-enabled'));
    /*
        addToColumnArray =>
        funtion that adds content to a 2 dimenstional Array to display content in rows and columns
        i.e if two component has same columns then they will be displayed in 2 rows

        columnsArray =  |link, link, Social Links|
                        |CopyRight (centered)    |

        copyright has index 1 and placed after link in contentstack
    */
    const addToColumnArray = (component, column) => {
        const elementOnIndex = columnsArray[column - 1];
        if (elementOnIndex) {
            columnsArray[column - 1].push(component);
        } else {
            columnsArray[column - 1] = [component];
        }
    };

    // Extract content from row and add into columnsArray

    if (footerV3Enabled && blockKey === 'footerTop') {
        addToColumnArray(
            <FooterTop data={row} />,
            1,
        );
    }

    for (let i = 0; i < row.length; i += 1) {
        const key = Object.keys(row[i])[0];
        switch (key) {
            case 'links':
                addToColumnArray(
                    <FooterLink key={seed(row[i][key])} links={row[i][key]} />,
                    row[i][key].column || 1,
                );
                break;
            case 'copy':
                addToColumnArray(
                    <FooterCopy key={seed(row[i][key])} data={row[i][key]} />,
                    row[i][key].column || 1,
                );
                break;
            case 'menu_group_links':
                addToColumnArray(
                    isMobile
                        ? (
                            <MobileFooterBlock
                                key={seed(row[i][key])}
                                block={row[i]}
                                isFooterV2
                                presentationFamily={presentationFamily}
                            />
                        )
                        : <DesktopFooterBlock key={seed(row[i][key])} block={row[i]} />,
                    1,
                );
                break;
            case 'social_links':
                addToColumnArray(
                    <FooterSocialLinks key={seed(row[i][key])} data={row[i][key]} isMobile={isMobile} />,
                    row[i][key].column || 1,
                );
                break;
            case 'popup_links':
                addToColumnArray(
                    <PopupLinks key={seed(row[i][key])} data={row[i][key]} />,
                    1,
                );
                break;
            case 'subscribe_box':
                if (!footerV3Enabled) {
                    addToColumnArray(
                        <EmailSubscribeBox key={seed(row[i][key])} data={row[i][key]} />,
                        row[i][key].column || 1,
                    );
                }
                break;
            case 'phone':
                return (
                    <CustomerServiceBlock
                        phoneNumber={row[i][key]?.link?.href}
                        chatBlock={chatBlock}
                        foodPresentation={presentationFamily}
                    />
                );
            default:
                break;
        }
    }

    // Creates structure to display the columnsArray
    let totalRows = 0;
    if (columnsArray.length) {
        totalRows = Math.max(...columnsArray.map((el) => el.length));
        for (let i = 0; i < totalRows; i += 1) {
            const columnsData = (
                <div key={seed(i)} className={isMobile ? '' : `${classes.desktopRowContainer} ${footerV3Enabled ? classes.footerV3Container : ''}`} data-testid="footer-sub-rows">
                    {columnsArray.map((column) => (
                        column[i]
                            ? (
                                column[i]
                            )
                            : null
                    ))}
                </div>
            );
            rowsArray.push(columnsData);
        }
    }

    return (
        <>
            <div data-testid="footer-row">
                {rowsArray.map((rowComp) => rowComp)}
            </div>
        </>
    );
};

FooterRow.propTypes = {
    row: array,
    presentationFamily: string,
    isMobile: bool,
    blockKey: string,
    chatBlock: object,
};

FooterRow.defaultProps = {
    row: [],
    isMobile: false,
    presentationFamily: 'food',
    blockKey: '',
    chatBlock: {},
};

export default FooterRow;
