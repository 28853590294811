/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import { shape, arrayOf, string } from 'prop-types';
import { makeStyles } from '@material-ui/core';
import PopupModal from './PopupModal';

const useStyles = makeStyles(() => ({
    container: {
        textAlign: 'center',
        width: '100%',
    },
    link: {
        fontSize: '13px',
        cursor: 'pointer',
        textDecoration: 'underline',
        margin: '0 5px',
    },
}));

const PopupLinks = ({ data }) => {
    if (!data) return null;
    const { link_group } = data;
    const classes = useStyles();
    const [dialog, setDialog] = useState(false);
    const [popupTitle, setPopupTitle] = useState('');
    const [popupData, setPopupData] = useState('');

    const closeDialog = () => {
        setDialog(false);
        setPopupTitle('');
        setPopupData('');
    };

    return (
        <div className={classes.container}>
            {link_group?.map((item) => (
                <span
                    className={classes.link}
                    key={`${item.link.title}`}
                    onClick={() => {
                        setDialog(true);
                        setPopupTitle(item.popup_title);
                        setPopupData(item.popup_content);
                    }}
                    role="presentation"
                    title={item.link.title}
                >
                    {item.link.title}
                </span>
            ))}
            <PopupModal
                show={dialog}
                closeDialog={closeDialog}
                title={popupTitle}
                data={popupData}
            />
        </div>
    );
};

PopupLinks.propTypes = {
    data: shape({
        link_group: arrayOf(shape({
            link: shape({
                title: string.isRequired,
                popup_title: string,
                popup_content: string,
            }),
        })),
    }),
};

PopupLinks.defaultProps = {
    data: {},
};

export default PopupLinks;
