/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    object, string, func, shape, array,
} from 'prop-types';
import { withStyles } from '@material-ui/core';
import LegalLinksContainer from '../GraphqlFooter/LegalLinksContainer';
import ShopTheSiteBanner from '../../../pages/Account/components/GiftList/ShopTheSiteBanner';
import getStyleOverrides from './Partials/getStyleOverrides';
import { setAbcChatLink } from '../../../../state/ducks/App/App-Actions';
import { getBrand, getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import LegalRecaptchaLinks from '../GraphqlFooter/LegalRecaptchaLinks';

const styles = (theme) => ({
    textCenter: {
        textAlign: 'center',
    },
    foodMobileFooter: {
        marginTop: '50px',
        display: 'block',
        paddingBottom: 48,
        position: 'relative',
        '& a': {
            textDecoration: 'none',
            '&.call-info': {
                margin: '-28px 0 0 0',
                display: 'inline-block',
                '& svg': {
                    color: theme?.palette?.common?.white,
                    margin: '0 auto',
                    display: 'block',
                    padding: '10px 8px',
                    fontSize: '55px',
                    borderRadius: '50%',
                    backgroundColor: theme?.palette?.secondary?.main,
                },
                '& div': {
                    color: theme?.palette?.primary?.main,
                    padding: '5px 8px',
                    fontSize: '16px',
                },
            },
        },
        [theme.breakpoints.down(960)]: {
            marginTop: '0',
            paddingTop: '50px',
        },
    },
});

const MobileGraphqlFooter = ({
    data, classes, getFooterRows,
}) => {
    const dispatch = useDispatch();
    const isRecaptchaCheckoutEnabled = useSelector(getFeatureFlag('is-giftcard-btn-captcha-enabled'))
        || useSelector(getFeatureFlag('is-placeorder-btn-captcha-enabled'));
    const brand = useSelector(getBrand);

    if (!data) return null;
    const chatObject = data?.mobile_customer_service?.find((item) => item?.chat?.link?.href);
    const link = chatObject?.chat?.link?.href;
    if (link) dispatch(setAbcChatLink(link));

    return (
        <div className={classes.foodMobileFooter} style={getStyleOverrides(data)} data-testid="footer-mobile">
            {getFooterRows(data, true)}
            <LegalLinksContainer />
            <LegalRecaptchaLinks isRecaptchaCheckoutEnabled={isRecaptchaCheckoutEnabled} legalData={data} brand={brand} />
            <ShopTheSiteBanner />
        </div>
    );
};

MobileGraphqlFooter.propTypes = {
    data: shape({
        background_color: shape({
            color: string,
        }).isRequired,
        text_color: shape({
            color: string,
        }).isRequired,
        footer_top: array,
        footer_middle: array,
        footer_bottom: array,
    }),
    classes: object.isRequired,
    getFooterRows: func.isRequired,
};

MobileGraphqlFooter.defaultProps = {
    data: {},
};

export default withStyles(styles)(MobileGraphqlFooter);
