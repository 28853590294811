/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    bool, shape, number, string, arrayOf,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const useStyles = makeStyles(() => ({
    container: ({ socialTextAlign, isMobile }) => ({
        display: 'flex',
        width: 'fit-content',
        margin: `10px ${isMobile ? 'auto' : '0'}`,
        textAlign: socialTextAlign,
    }),
    links: {
        margin: '0 5px',
        display: 'inline',
    },
    icon: ({ socialIconWidth }) => ({
        width: socialIconWidth ? `${socialIconWidth}px` : '50px',
    }),

    footerV3Container: ({ isMobile }) => ({
        display: 'flex',
        width: '100%',
        margin: `10px ${isMobile ? 'auto' : '15px 0px 0px'}`,
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        '&::before,&::after': {
            width: '50%',
            border: isMobile ? 'none' : '0.5px inset rgba(47, 47, 47, 0.2)',
            content: '""',
            display: 'inline-block',
            position: 'relative',
            verticalAlign: 'middle',
        },
        '&::after': {
            marginRight: '-50%',
            left: '.5em',
        },
        '&::before': {
            marginLeft: '-50%',
            right: '0.5em',
        },
    }),
}));

const FooterSocialLinks = ({ data, isMobile }) => {
    const socialIconWidth = data?.icon_width;
    const socialTextAlign = data?.justify;
    const footerV3Enabled = useSelector(getFeatureFlag('is-footer-v3-enabled'));
    const classes = useStyles({
        socialIconWidth,
        socialTextAlign,
        isMobile,
    });

    return (
        <div className={`${classes.container} ${footerV3Enabled ? classes.footerV3Container : ''}`}>
            {data?.social_link_group?.map((link) => {
                const getFileName = link?.icon?.title.split('.').slice(0, -1).join('.');
                return (
                    <a key={link?.url?.title} tabIndex="0" className={classes.links} href={link?.url?.href}><img className={classes.icon} src={link.icon.url} alt={`Socail Icon ${getFileName}`} height="30px" width="30px" /></a>
                );
            })}
        </div>
    );
};

FooterSocialLinks.propTypes = {
    data: shape({
        icon_width: number.isRequired,
        social_link_group: arrayOf(shape({
            url: shape({
                title: string.isRequired,
                href: string.isRequired,
            }),
            icon: shape({
                url: string.isRequired,
            }),
        })),
    }).isRequired,
    isMobile: bool.isRequired,
};

export default FooterSocialLinks;
