/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Query } from '@apollo/client/react/components';
import mbpLogger from 'mbp-logger';
import { GRAPHQL_ENV } from '../../../gql';
import findContent from '../../../gql/queries/findContent';
import LegalLinks from './LegalLinks';

const LegalLinksContainer = () => {
    const getContentTypeData = {
        brand: 'global',
        GRAPHQL_ENV,
        contentType: 'footer_legal_links',
    };
    const LEGAL_QUERY = findContent(getContentTypeData);

    return (
        <div data-component="LegalLinks">
            <Query query={LEGAL_QUERY}>
                {({ loading, error, data }) => {
                    if (loading) {
                        return null; // skeleton component
                    }

                    if (error) {
                        mbpLogger.logError({
                            appName: process.env.npm_package_name,
                            query: LEGAL_QUERY,
                            component: 'LegalLinks.js',
                            message: 'Error loading data from Graphql',
                            env: GRAPHQL_ENV,
                            error,
                        });
                        return null;
                    }

                    if (!data) {
                        mbpLogger.logWarning({
                            appName: process.env.npm_package_name,
                            query: LEGAL_QUERY,
                            component: 'LegalLinks.js',
                            message: 'No content returned for query',
                            env: GRAPHQL_ENV,
                        });
                        return null;
                    }
                    const response = data.findContent?.content?.entries?.[0];
                    if (response?.modular_blocks?.length) {
                        return <LegalLinks data={response} />;
                    }
                    return '';
                }}
            </Query>
        </div>
    );
};

export default LegalLinksContainer;
