/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    shape, arrayOf, string, func,
} from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import ifExternalUrl from '../../../../helpers/contentstack/ifExternalUrl';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';

const useStyles = makeStyles((theme) => ({
    container: (styling) => ({
        display: 'flex',
        width: 'auto',
        flexFlow: 'row wrap',
        justifyContent: styling?.justify || 'left',
    }),
    // Footer buttons are only being used in WLF and HD with same styles as below
    link: {
        border: '1px solid #cbcbcb',
        textDecoration: 'none',
        padding: '14px 20px',
        borderRadius: '5px',
        fontWeight: '600',
        display: 'flex',
        transition: 'all .3s',
        alignItems: 'center',
        fontSize: '14px',
        width: '260px',
        fontFamily: theme.typography.fontFamily,
        color: 'inherit',
        margin: '20px 20px 0',
    },
    icon: {
        margin: '0 20px 0 0',
        width: '100%',
        maxWidth: '42px',
    },
    menuLink: {
        color: 'inherit',
        padding: '5px 10px',
        flexShrink: '0',
        fontSize: 14,
        '-ms-flex-negative': '0',
        // important is added to overwrite parent property
        textDecoration: 'underline !important',
    },
    imageContainer: {
        display: 'flex',
    },
    title: {
        display: 'flex',
    },
    footerV3Container: {
        marginBottom: '40px',
        '& $link': {
            border: 'none',
            textDecoration: 'none',
            padding: '0px',
            borderRadius: '0',
            fontWeight: '700',
            alignItems: 'center',
            fontSize: '16px',
            width: '338px',
            justifyContent: 'center',
            margin: '20px 16px 0px 0px',
            height: '91px',
            boxShadow: '2px 2px 4px rgba(47, 47, 47, 0.15)',
            background: theme.palette.common.white || '#fff',

        },
        '& $icon': {
            maxHeight: '42px',
        },
        '& $imageContainer': {
            width: '58px',
            height: '58px',
            background: theme.palette.footerLinkImgBg || '#E8F1ED',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0px 18px',
            '& img': {
                margin: '0 auto',
                maxHeight: '24px',
            },
        },
        '& $title': {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            maxWidth: '170px',
            marginRight: '10px',
        },
        [theme.breakpoints.down(1366)]: {
            '& $link': {
                width: '315px',
            },
            '& $imageContainer': {
                margin: '0px 13px 0px 10px',
            },
        },
        [theme.breakpoints.down(1024)]: {
            '& $link': {
                width: '287px',
            },
        },
        [theme.breakpoints.down(767)]: {
            marginLeft: '12px',
            '& $link': {
                width: '100%',
            },
        },
        '@media screen and (max-width: 1023px) and (min-width:768px)': {
            '& $link': {
                width: '47.5%',
            },
        },
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const FooterLink = ({ links, track }) => {
    const { justify, links_group } = links || {};
    const classes = useStyles({ justify });
    const footerV3Enabled = useSelector(getFeatureFlag('is-footer-v3-enabled'));
    return (
        <div className={`${classes.container} ${footerV3Enabled ? classes.footerV3Container : ''}`} data-testid="footer-link">
            {links_group?.map((link) => (
                link.type === 'Button'
                    ? (
                        <Link
                            key={link.title}
                            to={link.url?.href}
                            className={classes.link}
                            onClick={(event) => {
                                track({
                                    eventCategory: link?.tracking_event_category || '',
                                    eventAction: link?.tracking_event_action || '',
                                    eventLabel: link?.tracking_event_label || '',
                                });
                                ifExternalUrl(event, link.url?.href);
                            }}
                        >
                            {link.icon?.url
                                ? <span className={classes.imageContainer}><img className={classes.icon} src={link.icon.url} alt="img" /></span>
                                : null}
                            <span className={classes.titleContainer}><span className={classes.title}>{link.title} </span>{footerV3Enabled && <ChevronRightIcon className={classes.chevron} />}</span>
                        </Link>
                    ) : (
                        <Link
                            className={classes.menuLink}
                            to={link.url?.href}
                            key={link.title}
                            onClick={() => {
                                track({
                                    eventCategory: link?.tracking_event_category || '',
                                    eventAction: link?.tracking_event_action || '',
                                    eventLabel: link?.tracking_event_label || '',
                                });
                            }}
                        >
                            {link.title}
                        </Link>
                    )))}
        </div>
    );
};

FooterLink.propTypes = {
    links: shape({
        links_group: arrayOf(shape({
            type: string.isRequired,
            title: string.isRequired,
            url: shape({
                href: string.isRequired,
            }),
            icon: shape({
                url: string.isRequired,
            }),
        })),
    }).isRequired,
    track: func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    track: bindActionCreators(trackEvent, dispatch),
});

export default connect(
    null,
    mapDispatchToProps,
)(FooterLink);
