/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { array } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useUIDSeed } from 'react-uid';
import EmailSubscribeBox from '../../../ContentTypeComponents/EmailSubscrube/EmailSubscribeBox';
import FooterCard from './FooterCard';
import EmailAnchor from './EmailAnchor';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        alignItems: 'center',
        width: '100%',
        columnGap: '16px',
    },
    fourPanelContainer: {
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridTemplateRows: '1fr',
        [theme.breakpoints.down(1366)]: {
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
        },
        [theme.breakpoints.down(1024)]: {
            gridTemplateColumns: '1fr 1fr',
        },
        [theme.breakpoints.down(767)]: {
            gridTemplateColumns: '1fr',
        },
    },
    threePanelContainer: {
        gridTemplateColumns: '1fr 1fr 1fr',
        gridTemplateRows: '1fr',
        [theme.breakpoints.down(1024)]: {
            gridTemplateColumns: '1fr 1fr',
            '& $gridLayout': {
                '&:last-child': {
                    gridColumn: '1/3',
                    gridRow: '2/3',
                },
            },
        },
        [theme.breakpoints.down(768)]: {
            gridTemplateColumns: '1fr',
            '& $gridLayout': {
                '&:last-child': {
                    gridColumn: 'auto',
                    gridRow: 'auto',
                },
            },
        },
    },
    twoPanelContainer: {
        gridTemplateColumns: '1fr 1fr',
        [theme.breakpoints.down(767)]: {
            gridTemplateColumns: '1fr',
        },
    },
    gridLayout: {},
}));

const FooterTop = ({
    data,
}) => {
    if (!data) return null;
    const seed = useUIDSeed();
    const classes = useStyles();
    const cards = [];
    data.forEach((element) => {
        if (element.card || element.subscribe_box || element.email_signup) {
            cards.push(element);
        }
    });
    const gridContainerClasses = { // key is number of columns
        2: classes.twoPanelContainer,
        3: classes.threePanelContainer,
        4: classes.fourPanelContainer,
    };
    return (
        <div  className={`${classes.container} ${gridContainerClasses[cards.length]}`}>
            {cards.map((item) =>  {
                const columns = [];
                const key = Object.keys(item)[0];
                const blockObj = item[key];
                switch (key) {
                    case 'subscribe_box':
                        columns.push(<div className={classes.gridLayout} key={seed(item)}><EmailSubscribeBox data={blockObj} /></div>);
                        break;
                    case 'card':
                        columns.push(<div className={classes.gridLayout} key={seed(item)}><FooterCard link={blockObj} /></div>);
                        break;
                    case 'email_signup':
                        columns.push(<div className={classes.gridLayout} key={seed(item)}><EmailAnchor /></div>);
                        break;
                    default:
                        break;
                }
                return columns;
            })}
        </div>
    );
};

FooterTop.propTypes = {
    data: array,
};

FooterTop.defaultProps = {
    data: [],
};

export default FooterTop;
