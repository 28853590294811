/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const useStyles = makeStyles(() => ({
    emailAnchor: {
        '@media screen and (max-width: 767px)': {
            marginBottom: '2.5rem',
        },
        '@media screen and (max-width: 1024px) and (min-width: 737px)': {
            '& [class*="bx-wrap"]': {
                width: '100% !important',
            },
        },
    },
}));

const EmailAnchor = () => {
    const classes = useStyles();
    const emailAnchorRef = useRef(null);
    const dispatch = useDispatch();
    useEffect(() => {
        const observeDOM = () => {
            const observer = new MutationObserver(() => {
                if (emailAnchorRef.current) {
                    const component = emailAnchorRef.current;
                    // Do not change this
                    const regex = /thank you for signing up/;
                    if (component && !component.hasListenerAttached && component?.querySelector('form') && component.outerText) {
                        if (regex.test(component.outerText.toLowerCase())) {
                            dispatch(trackEvent(
                                {
                                    eventCategory: 'Wunderkind',
                                    eventAction: 'submit',
                                    eventName: 'feature_interaction',
                                    feature_detail: 'email subscription footer',
                                    feature_element: 'submit',
                                    feature_category: 'Wunderkind',
                                },
                            ));
                            component.hasListenerAttached = true; // Flag to indicate the listener is attached
                            observer.disconnect();
                        }
                    }
                }
            });
            observer.observe(document.body, { childList: true, subtree: true });
        };

        if (typeof document !== 'undefined') {
            observeDOM();
        }
    }, []);

    return (
        <div ref={emailAnchorRef} className={classes.emailAnchor}>
            <div data-emailanchor="true" />
        </div>
    );
};

export default EmailAnchor;
