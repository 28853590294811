/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

export default (data) => {
    const {
        background_color: { color } = {},
        text_color = {},
    } = data;

    return {
        backgroundColor: color,
        color: text_color.color,
    };
};
